/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Callout
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$claim-font-size: $font-size-regular;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-claim {
  --foreground: var(--color-white);
  --background: var(--color-primary-700);

  position: relative;
  z-index: 1;
  padding: rem($spacing-regular);
  padding-top: rem(60px);
  @include fluid-size($spacing-large, $spacing-huge, padding-bottom);
  font-size: rem($claim-font-size);
  color: var(--foreground);

  > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  > * + * {
    margin-top: rem($spacing-large);
  }

  &::before {
    content: "";
    position: absolute;
    top: rem(60px);
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    background-color: var(--background);
  }
}

  .c-claim__head {
    display: block;
    width: rem(450px);
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
  }

    .c-claim__illustration {
      display: block;
      width: 100%;
      height: auto;
      margin-top: rem(-15%);
    }

  .c-claim__body {}

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@media print {
  .c-claim {
    break-inside: avoid;

    &::before {
      border: rem(1px) solid color("black");
    }
  }
}
