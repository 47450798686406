/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Card
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$card-font-size: $font-size-regular;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-card {
  position: relative;
  font-size: rem($card-font-size);

  > * + * {
    margin-top: rem($spacing-regular);
  }
}

  .c-card__head {
    line-height: 0;
  }

    .c-card__figure {}

  .c-card__body {
    > * + * {
      margin-top: rem($spacing-small);
    }
  }

    .c-card__badges,
    .c-card__buttons {
      --space: #{ rem($spacing-small) };
    }

    .c-card__title {
      @include h5;
      @include title($root: "card");

      font-family: var(--font-brand);
      font-weight: $font-bold;
    }

      .c-card__mainlink {
        text-decoration: none;
      }

    .c-card__buttons {
      margin-top: rem($spacing-regular);
    }

/*******************************************************************************
 * States
*******************************************************************************/

.c-card--interactive {
  cursor: pointer;

  .c-card__figure {
    @include drop-shadow-off;
    @include transition(filter);
  }

  &:hover {
    .c-card__figure {
      @include drop-shadow-on;
    }
  }
}

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@media print {
  .c-card {
    break-inside: avoid;
  }
}
