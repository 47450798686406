/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Magazine
 * Type: Component
**/

/*******************************************************************************
 * Defaut styles
*******************************************************************************/

.c-magazine {
  position: relative;
}

  .c-magazine__decoration {
    position: absolute;
    bottom: 0;
    left: 50%;
    max-width: 100%;
    height: auto;
    transform: translateX(-50%);
  }

    .c-magazine__cover {
      padding: 0 25.833333% 2.5%;
    }

      .c-magazine__figure {
        @include shadow;
      }

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-magazine {
    break-inside: avoid;
  }
}
