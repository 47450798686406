/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Checklist
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$checklist-font-size: $font-size-regular;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-checklist {
  --icon: inherit;

  font-size: rem($checklist-font-size);
}

  .c-checklist__items {
    padding: 0;
    margin: 0;
    list-style: none;

    > * {
      margin-top: 0;
      margin-bottom: 0;
    }

    > * + * {
      margin-top: 1.25rem;
    }
  }

    .c-checklist__item {
      display: flex;
      gap: 0.75rem;
    }

      .c-checklist__icon {
        flex: 0 0 auto;
        font-size: 1.5em;
        color: var(--icon);
      }

      .c-checklist__stamp {
        display: inline-block;
        align-self: flex-start;
        padding: em(1px 5px, 10px);
        font-size: em(10px, $checklist-font-size);
        font-weight: $font-bold;
        text-transform: uppercase;
        color: color("white");
        background-color: color("black");
        transform: rotate(-6deg);
      }

      .c-checklist__label {
        flex: 1 1 auto;
      }

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@media print {
  .c-checklist {
    break-inside: avoid;
  }

    .c-checklist__stamp {
      color: color("white") !important;
      background-color: color("black") !important;
    }
}
