/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Toaster
 * Type: Component
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-toaster {
  --toaster-y: 0;

  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: $global-toaster-zindex;
  display: inline-flex;
  align-items: center;
  width: max-content;
  max-width: calc(100% - #{ rem($spacing-regular * 2) });
  padding: 1em;
  @include h6;
  font-weight: $font-bold;
  color: var(--color-foreground);
  background-color: var(--color-background);
  box-shadow: rem(0 0 20px) var(--shadow);
  transform: translate(-50%, 0);
  animation: toaster-show $animations-transition-duration $animations-timing-function;
  @include transition(transform);

  > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  &[hidden] {
    display: none;
  }

  &.is-hiding {
    transform: translate(-50%, 100%);
  }
}

  .c-toaster__pastil {
    margin-right: 0.5em;
    font-size: 2em;
  }

  .c-toaster__text {}

/*******************************************************************************
 * Animations
*******************************************************************************/

@keyframes toaster-show {
  from {
    transform: translate(-50%, 100%);
  }

  to {
    transform: translate(-50%, 0);
  }
}
