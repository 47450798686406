/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Price
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$price-font-size: $font-size-regular;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-price {
  display: inline-block;
  font-size: rem($price-font-size);
}

  .c-price__value,
  .c-price__currency {
    font-family: var(--font-brand);
    font-size: 1.5em;
    font-weight: $font-bold;
  }

  .c-price__description {
    margin-left: 0.5ch;
  }

/*******************************************************************************
 * Variants
*******************************************************************************/

.c-price--big {
  .c-price__value,
  .c-price__currency {
    font-size: 2.25em;
  }
}

.c-price--emphasis {
  .c-price__value,
  .c-price__currency {
    font-style: italic;
  }
}

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@media print {
  .c-price {
    break-inside: avoid;
  }
}
