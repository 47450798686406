/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Photos
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$photos-delta-block: 4%;
$photos-delta-inline: 15%;

/*******************************************************************************
 * Default styles
*******************************************************************************/

// 384/513

.c-photos {
  --ratio: 0.7485380117;
  --color-photos-back: var(--color-white);
  --color-photos-middle: var(--color-primary-400);
  --color-photos-front: var(--color-primary-500);

  position: relative;
  display: inline-block;
  padding: $photos-delta-block $photos-delta-inline;
  line-height: 0;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: $photos-delta-block;
    right: $photos-delta-inline;
    bottom: $photos-delta-block;
    left: $photos-delta-inline;
    display: block;
    width: 100% - $photos-delta-inline * 2;
    height: 100% - $photos-delta-block * 2;
    border-radius: 1% / calc(var(--ratio) * 1%);
  }

  &::before {
    background-color: var(--color-photos-back);
    transform: translateX(13%) rotate(6deg);
  }

  &::after {
    background-color: var(--color-photos-middle);
    transform: translateX(-13%) rotate(-6deg);
  }
}

  .c-photos__cover {
    position: relative;
    z-index: 1;
    overflow: hidden;
    background-color: var(--color-photos-front);
    border-radius: 1% / calc(var(--ratio) * 1%);
    @include shadow;
  }

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-photos {
    break-inside: avoid;

    &::before {
      background-color: color("neutral-100") !important;
    }

    &::after {
      background-color: color("neutral-400") !important;
    }
  }

    .c-photos__cover {
      background-color: color("neutral-500") !important;
    }
}
