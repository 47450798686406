/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Wrapper
 * Type: Object
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:map";

/*******************************************************************************
 * Variables
*******************************************************************************/

$breadcrumb-font-size: $font-size-tiny;
$breadcrumb-icon-prev: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m10.41 12 5.29-5.29a.996.996 0 1 0-1.41-1.41l-6 6a.996.996 0 0 0 0 1.41l6 6c.2.2.45.29.71.29s.51-.1.71-.29a.996.996 0 0 0 0-1.41l-5.29-5.29Z" fill="%color%"/></svg>';
$breadcrumb-icon-next: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m15.71 11.29-6-6A.996.996 0 1 0 8.3 6.7l5.29 5.29-5.29 5.29a.996.996 0 0 0 .71 1.7c.26 0 .51-.1.71-.29l6-6a.996.996 0 0 0 0-1.41Z" fill="%color%"/></svg>';

 /**
  * Styles
  **********************************************************************************/

 .c-breadcrumb {
   font-size: rem($breadcrumb-font-size);
 }

  .c-breadcrumb__items {
    display: block;
    padding: 0;
    margin: 0;
  }

    .c-breadcrumb__item {
      display: none;

      &::before,
      &::after {
        content: "";
        display: inline-block;
        width: 1em;
        height: 1em;
        margin-right: 0.15em;
        font-size: 1.25em;
        font-weight: $font-bold;
        vertical-align: middle;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }

      &::before {
        background-image: icon($breadcrumb-icon-prev, color("black"));
      }

      &::after {
        display: none;
        background-image: icon($breadcrumb-icon-next, color("black"));
      }

      &:nth-last-child(2) {
        display: inline-block;
      }
    }

    .c-breadcrumb__item--current {
      font-weight: $font-bold;
    }

      .c-breadcrumb__link {
        display: inline-block;
        text-decoration: none;
        color: inherit;

        &:hover {
          text-decoration: underline;
        }
      }

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@include mq($until: regular) {
  .c-breadcrumb__item {
    font-weight: $font-bold;
  }
}

@include mq($from: regular) {
  .c-breadcrumb__item {
    display: inline-block;

    &::before {
      display: none;
    }

    &::after {
      display: inline-block;
    }
  }

  .c-breadcrumb__item--current {
    &::after {
      display: none;
    }
  }
}

/*******************************************************************************
 * Modifiers
*******************************************************************************/

.c-breadcrumb--light {
  .c-breadcrumb__item {
    &::before {
      background-image: icon($breadcrumb-icon-prev, color("white"));
    }

    &::after {
      background-image: icon($breadcrumb-icon-next, color("white"));
    }
  }

}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-breadcrumb {
    break-inside: avoid;
    display: none !important;
  }
}
