/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Panel
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$panel-font-size: $font-size-small;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-panel {
  --foreground: var(--color-primary-900);
  --background: var(--color-primary-50);
  --border: transparent;
  --icon: var(--color-primary-600);

  display: flex;
  padding: rem(14px);
  font-size: rem($panel-font-size);
  color: var(--foreground);
  background-color: var(--background);
  border: rem(1px) solid var(--border);
  border-radius: rem(10px);
}

  .c-panel__head,
  .c-panel__body {
    padding: rem(5px);
  }

  .c-panel__head {
    flex: 0 0 auto;
    align-self: center;
  }

    .c-panel__icon {
      font-size: em(28px, $panel-font-size);
      color: var(--icon);
    }

  .c-panel__body {
    flex: 1 1 auto;
  }

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@include mq($from: regular) {
  .c-panel:not(.c-panel--frame) {
    padding: rem(25px);

    .c-panel__head,
    .c-panel__body {
      padding: rem(15px);
    }
  }
}

/*******************************************************************************
 * Modifiers
*******************************************************************************/

.c-panel--frame {
  --foreground: inherit;
  --background: transparent;
  --border: var(--color-primary-600);
}

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@media print {
  .c-panel {
    break-inside: avoid;
  }
}
