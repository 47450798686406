/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Cart Summary
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$cart-summary-font-size: $font-size-small;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-cart-summary {
  --background: var(--color-background);
  --foreground: var(--color-foreground);

  @include fluid-size(16px, 46px, padding);
  padding-top: rem(36px);
  padding-bottom: rem($spacing-large);
  font-size: rem($cart-summary-font-size);
  color: var(--foreground);
  background-color: var(--background);
  border: rem(4px) solid var(--color-primary-400);
  border-radius: rem(10px);

  > * {
    margin: 0;
  }

  > * + * {
    margin-top: rem($spacing-regular);
  }
}

  .c-cart-summary__head,
  .c-cart-summary__body {
    > * {
      margin: 0;
    }

    > * + * {
      margin-top: rem($spacing-regular);
    }
  }

  .c-cart-summary__head {}

    .c-cart-summary__title {
      @include h4;

      font-family: var(--font-brand);
      font-weight: $font-bold;
    }

  .c-cart-summary__body {}

    .c-cart-summary__header {
      > * {
        margin: 0;
      }

      > * + * {
        margin-top: rem($spacing-regular);
      }
    }

      .c-cart-summary__count {
        font-weight: $font-bold;
      }

    .c-cart-summary__main {}

      .c-cart-summary__details {}

      .c-cart-summary__items {
        padding: 0;
        margin: 0;
        list-style: none;
      }

        .c-cart-summary__item {
          padding: rem($spacing-small) 0;
        }

    .c-cart-summary__footer {
      padding-top: rem($spacing-small);
      border-top: rem(1px) solid var(--color-primary-400);
    }

  .c-cart-summary__foot {
    padding-top: rem($spacing-small);
    font-size: rem($font-size-regular);
    border-top: rem(1px) solid var(--color-primary-400);
  }

    .c-cart-summary__line {
      display: flex;
      padding: rem($spacing-small * 0.5) 0;

      > * {
        margin: 0;
      }

      .c-cart-summary__foot & {
        align-items: center;
      }
    }

      .c-cart-summary__label {
        flex: 1 1 auto;
        margin-right: rem($spacing-small);

        .c-cart-summary__foot & {
          font-weight: $font-bold;
        }
      }

      .c-cart-summary__price {
        flex: 0 0 auto;
        font-size: rem($font-size-regular);

        .c-cart-summary__foot & {
          @include h4;

          font-family: var(--font-brand);
          font-weight: $font-bold;
        }
      }

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@media print {
  .c-cart-summary {
    break-inside: avoid;
  }
}
