/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Price list
 * Type: Component
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-price-list {}

  .c-price-list__title {
    @include h5;
    font-family: var(--font-brand);
    font-weight: $font-bold;
    text-align: center;
    color: var(--color-foreground);
  }

  .c-price-list__items {}

    .c-price-list__item {
      display: flex;
      padding: 1rem 0;
      border-top: rem(1px) solid var(--color-neutral-300);
    }

      .c-price-list__label {
        flex: 1 1 auto;
      }

      .c-price-list__price {
        flex: 0 0 auto;
        font-weight: $font-bold;
      }
