/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Flexible Table
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$flexible-table-font-size: $font-size-regular;
$flexible-table-max-lines: 2;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-flexible-table {
  --background: transparent;
  --label-color: var(--color-primary-600);
  --emphasis-color: var(--color-primary-800);

  position: relative;
  z-index: 1;
  font-size: rem($flexible-table-font-size);
}

  .c-flexible-table__items {
    padding: 0;
    margin: 0;
    list-style: none;
  }

    .c-flexible-table__item {
      position: relative;
      display: flex;
      flex-wrap: wrap;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background-color: var(--background);
        border-radius: rem(10px);
      }

      &.c-flexible-table__item--current {
        color: var(--emphasis-color);
      }

      &:nth-child(odd) {
        --background: var(--color-white);
      }
    }

      .c-flexible-table__cell {
        margin: 0;
        font-size: rem($flexible-table-font-size);
        font-weight: $font-regular;
        line-height: inherit;

        &.c-flexible-table__cell--small {
          width: rem(120px);
        }

        &.c-flexible-table__cell--medium {
          width: rem(140px);
        }

        &.c-flexible-table__cell--large {
          width: rem(220px);
        }

        &.c-flexible-table__cell--fluid {
          display: flex;
          flex: 1 1 var(--basis, 0%);
          flex-direction: column;
          width: var(--size, #{ rem(280px) });
          max-width: 100%;
        }

        &.c-flexible-table__cell--action {
          flex: 1 0 auto;
          align-self: center;
          text-align: right;
        }
      }

        .c-flexible-table__label {
          display: block;
          font-size: $font-size-small;
          color: var(--label-color);
        }

        .c-flexible-table__value {
          display: block;

          &.c-flexible-table__value--mainlink {
            text-decoration: none;

            &::before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              border-radius: rem(10px);
            }

            &:hover {
              text-decoration: underline;
            }
          }

          .c-flexible-table__cell--hidden &,
          .c-flexible-table__cell--fluid & {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@include mq($until: regular) {
  .c-flexible-table__item {
    padding: rem(10px);
  }

    .c-flexible-table__cell {
      flex-basis: 50%;
      padding: rem(5px);

      &.c-flexible-table__cell--small {
        flex-basis: 50%;
      }
    }
}

@include mq($from: regular) {
    .c-flexible-table__item {
      padding: rem(5px);
    }

      .c-flexible-table__cell {
        flex: 0 1 auto;
        padding: rem(15px);
      }
}

/*******************************************************************************
 * Modifiers
*******************************************************************************/

.c-flexible-table--one-line {
  .c-flexible-table__items {
    padding: 0;
  }

    .c-flexible-table__item {
      --background: transparent;

      margin: rem(-15px);
    }
}

.c-flexible-table--wrap {
  .c-flexible-table__cell--fluid .c-flexible-table__value {
    overflow: initial;
    text-overflow: initial;
    white-space: initial;
  }
}

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@media print {
  .c-flexible-table {
    break-inside: avoid;
  }

    .c-flexible-table__item {
      &:nth-child(odd)::before {
        border: rem(1px) solid color("black");
      }
    }
}
