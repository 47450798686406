/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Stepper
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:math";

/*******************************************************************************
 * Variables
*******************************************************************************/

$stepper-font-size: $font-size-small;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-stepper {
  display: inline-block;
  overflow: hidden;
  font-size: rem($stepper-font-size);
  font-weight: $font-bold;
  text-transform: uppercase;
}

  .c-stepper__items {
    display: flex;
    padding: 0;
    margin: 0 rem(-$spacing-regular);
    list-style: none;
    counter-reset: stepper;
  }

    .c-stepper__item {
      counter-increment: stepper;

      + .c-stepper__item {
        &::before {
          content: "";
          display: inline-block;
          width: em($spacing-regular, $stepper-font-size);
          vertical-align: middle;
          border-top: rem(1px) solid currentcolor;
          opacity: 0.5;
        }
      }
    }

      .c-stepper__control {
        padding: 0 rem($spacing-regular);
        opacity: 0.5;

        &::before {
          content: counter(stepper);
          display: inline-block;
          width: 1.5em;
          height: 1.5em;
          margin-right: em($spacing-small, $stepper-font-size);
          text-align: center;
          border: rem(1px) solid currentcolor;
          border-radius: 50%;
        }

        .c-stepper__item.c-stepper__item--current & {
          opacity: 1;
        }
      }

      a.c-stepper__control {
        text-decoration: none;
        @include transition(opacity);

        &:hover {
          opacity: 1;
        }
      }

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@include mq($until: small) {
  .c-stepper {}

    .c-stepper__items {
      margin-right: rem(-1 * math.div($spacing-regular, 2));
      margin-left: rem(-1 * math.div($spacing-regular, 2));
    }

      .c-stepper__control {
        padding-right: rem(math.div($spacing-regular, 2));
        padding-left: rem(math.div($spacing-regular, 2));
      }

        .c-stepper__label {
          .c-stepper__item:not(.c-stepper__item--current) & {
            @include hidden-visually;
          }
        }
}

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@media print {
  .c-stepper {
    break-inside: avoid;
  }
}
