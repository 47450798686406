/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Tablist
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$tablist-font-size: $font-size-small;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-tablist {
  --color: inherit;
  --background: transparent;
  --border: currentcolor;

  display: inline-block;
  font-size: rem($tablist-font-size);

  &[data-tablist]:not(.is-mounted) {
    display: none;
  }
}

  .c-tablist__items {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
  }

    .c-tablist__item {
      flex: 0 0 auto;
    }

      .c-tablist__control {
        @include appearance;

        position: relative;
        display: inline-block;
        padding: em(9px 12px, $tablist-font-size);
        text-decoration: none;
        cursor: pointer;
        color: var(--color);
        background-color: var(--background);
        border: rem(1px) solid var(--border);

        .c-tablist__item:first-child & {
          border-radius: em(4px, $tablist-font-size) 0 0 em(4px, $tablist-font-size);
        }

        .c-tablist__item:last-child & {
          border-radius: 0 em(4px, $tablist-font-size) em(4px, $tablist-font-size) 0;
        }

        .c-tablist__item:not(:first-child) & {
          border-left: 0;
        }

        &[aria-selected="true"],
        &.is-selected {
          --background: var(--color-primary-800);
          --color: var(--color-white);
          --border: transparent;
        }
      }

      a.c-tablist__control,
      button.c-tablist__control {
        @include transition(background-color color);

        &:not([aria-selected]):not(.is-selected):hover {
          --background: var(--color-primary-100);
        }
      }

        .c-tablist__label {}

        .c-tablist__info {
          position: absolute;
          top: calc(100% + #{ em(14px, $tablist-font-size) });
          right: 0;
          display: inline-block;
          padding: em(5px, $tablist-font-size);
          font-family: var(--font-brand);
          font-weight: $font-bold;
          white-space: nowrap;
          color: var(--color-black);
          background-color: var(--color-warning-300);
          transform: translateX(50%);

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            width: 1em;
            height: 1em;
            font-size: em(9px, $tablist-font-size);
            background-color: var(--color-warning-300);
            transform: translate(-50%, -50%) rotate(0.125turn);
          }
        }

/*******************************************************************************
 * Modifiers
*******************************************************************************/

.c-tablist--info {
  padding-bottom: em(44px, $tablist-font-size);
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-tablist {
    break-inside: avoid;
  }

    .c-tablist__control {
      &[aria-selected="true"],
      &.is-selected {
        background-color: color("black") !important;

        > .c-tablist__label {
          color: color("white") !important;
        }
      }
    }

      .c-tablist__info {
        color: color("white") !important;
        background-color: color("black") !important;

        &::before {
          background-color: color("black") !important;
        }
      }
}
