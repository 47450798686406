/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Form's Block
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

$block-focus-outline-size: 1px;
$block-accent-color: var(--color-outline);

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-form-block {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: rem(20px);
  @include fluid-size(15px, 20px, padding-top);
  @include fluid-size(15px, 30px, padding-right);
  @include fluid-size(15px, 20px, padding-bottom);
  @include fluid-size(15px, 30px, padding-left);
  border: rem(1px) solid var(--color-primary-600);
  border-radius: rem(4px);
  @include transition(box-shadow);

  &:focus-within {
    box-shadow: 0 0 0 rem($block-focus-outline-size $block-accent-color);
  }

  &:not(.c-form-block--multiple) > .c-form-block__main > :not(.c-form__details) .c-form-option__control {
    position: static;
  }
}

  .c-form-block__main {
    flex-basis: 0;
    flex-grow: 999;
    min-width: 50%;
    color: var(--color-black);
  }

    .c-form-block__details {
      @include text-small;
      padding-left: rem(26px);

      &--spaced {
        padding-top: rem(20px);
      }
    }

  .c-form-block__aside {
    flex-basis: rem(300px);
    flex-grow: 1;
    align-self: center;
    width: rem(300px);
    max-width: 100%;
    text-align: right;
  }

      .c-form-block__action {
        z-index: 1;
      }
