/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Header
 * Type: Component
**/

$header-navigation-background: var(--color-secondary-50) !default;
$header-cart-count: var(--color-secondary-800) !default;

/*******************************************************************************
 * Utils
*******************************************************************************/

.c-header {
  --gap: #{ rem($spacing-tiny) };

  position: relative;
  z-index: $global-header-zindex;
  padding: rem(14px 0);
}

  .c-header__top {}

  .c-header__main {}

    .c-header__wrapper {}

      .c-header__items {
        display: flex;
        align-items: center;
        margin: calc(-1 * var(--gap));

        .c-header__top & {
          align-items: center;
          justify-content: flex-end;
        }
      }

        .c-header__item {
          padding: var(--gap);

          &--logo {
            flex-shrink: 1;
          }

          &--navigation {
            flex-grow: 1;
          }

          &--actions {
            text-align: right;
          }

          &::after {
            content: attr(data-cart-count);
            position: absolute;
            top: 0;
            right: 0;
            box-sizing: border-box;
            display: none;
            flex-direction: column;
            align-items: center;
            min-width: rem(24px);
            height: rem(24px);
            padding: rem(5px 2px);
            font-size: rem(12px);
            font-weight: $font-bold;
            line-height: 1;
            text-align: center;
            color: var(--color-white);
            background-color: $header-cart-count;
            border-radius: 50%;
            pointer-events: none;
            animation: header-notification-show ($animations-transition-duration * 0.5) $animations-timing-function;
          }

          &[data-cart-count]:not([data-cart-count=""]) {
            position: relative;

            &::after {
              display: inline-flex;
            }
          }
        }

          .c-header__link {
            --color-icon: inherit;

            max-width: rem(300px);
          }

          .c-header__burger {}

          .c-header__logo {
            font-size: rem(16px);
          }

          .c-header__navigation {
            display: none;

            .no-js &,
            .c-header.is-mounted &:not([aria-hidden]) {
              display: block;
            }

            &:not([aria-hidden]) {
              margin: 0;
            }

            &[aria-hidden] {
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              z-index: -1;
              height: 100vh;
              height: calc(100vh);
              padding-top: var(--header-height);
              overflow-x: hidden;
              overflow-y: auto;
              overscroll-behavior: contain;
              color: var(--color-foreground);
              background-color: $header-navigation-background;
              animation: header-navigation-show $animations-transition-duration $animations-timing-function;
              @include transition(opacity transform);
            }

            &.is-hiding {
              opacity: 0;
              transform: translate3d(0, #{ rem(-1 * $spacing-regular) }, 0);
            }

            &[aria-hidden="false"] { display: block; }
            &[aria-hidden="true"] { display: none; }
          }

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@include mq($until: large) {
  .c-header {
    display: flex;
    gap: rem(5px);
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding-right: rem(24px);
    padding-left: rem(24px);
  }

    .c-header__main {
      flex-basis: 20%;
      flex-grow: 1;
      padding-right: rem(10px);
    }

      .c-header__wrapper {
        --gutter: 0;
      }

      .c-header__item--navigation {
        padding: 0;
      }

    .c-header__top {
      flex-grow: 1;
      order: 1;
    }
}

@include mq($until: regular) {
  .c-header__main {
    flex-basis: 47%;
  }

    .c-header__item--desktop {
      display: none;
    }
}

@include mq($until: small) {
    .c-header__items {
      .c-header__top & {
        --gap: #{ rem(5px) };
      }
    }
}

@include mq($from: large) {
  .c-header__item--burger,
  .c-header__burger {
    display: none;
  }

  .c-header__logo {
    font-size: rem(22px);
  }

  .c-header__navigation {
    font-size: rem(18px);
  }
}

@include mq($from: huge) {
  .c-header__logo {
    font-size: rem(36px);
  }

  .c-header__navigation {
    --gap: #{ $spacing-small };

    font-size: rem(20px);
  }
}

/*******************************************************************************
 * Animations
*******************************************************************************/

@keyframes header-navigation-show {
  from {
    opacity: 0;
    transform: translate3d(0, #{ rem(-1 * $spacing-regular) }, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes header-notification-show {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-header {
    break-inside: avoid;
  }

    .c-header__item {
      &[data-cart-count]:not([data-cart-count=""]) {
        &::after {
          color: color("white") !important;
          background-color: color("black") !important;
        }
      }
    }
}
