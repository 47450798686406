/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Tile
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$tile-font-size: $font-size-small;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-tile {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @include fluid-size(20px, 30px, padding);
  font-size: rem($tile-font-size);
  color: var(--color-foreground);
  background-color: var(--color-white);
  border-radius: rem(10px);
  aspect-ratio: 1.4453125;

  > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  > * + * {
    @include fluid-size(15px, 30px, margin-top);
  }
}

  .c-tile__body {
    > * {
      margin-top: 0;
      margin-bottom: 0;
    }

    > * + * {
      margin-top: rem(4px);
    }
  }

    .c-tile__title {
      @include h4;
      @include title($root: "tile");
      font-family: var(--font-brand);
      font-weight: $font-bold;
    }

    .c-tile__subtitle {
      color: var(--color-primary-600);
    }

  .c-tile__foot {}

    .c-tile__link {}

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@media print {
  .c-tile {
    break-inside: avoid;
    border: rem(1px) solid color("black");
  }
}
