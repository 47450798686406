/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Cart
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$cart-font-size: $font-size-small;
$ripple-duration: 1.5s;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-cart {
  position: relative;
  font-size: rem($cart-font-size);

  > * {
    @include transition(opacity);
  }

  &.is-loading {
    pointer-events: none;

    > * {
      opacity: 0.25;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: rem(40px);
      height: rem(40px);
      background-color: var(--color-primary-500);
      border-radius: 50%;
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
      animation: ripple-effect $ripple-duration cubic-bezier(0, 0.2, 0.8, 1) infinite;
      will-change: transform;
    }

    &::before {
      opacity: 1;
      transform: translate(-50%, -50%) scale(0.5);
    }

    &::after {
      opacity: 0.5;
      transform: translate(-50%, -50%) scale(1);
      animation-delay: -$ripple-duration * 0.5;
    }
  }
}

  .c-cart__head {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: rem(30px);

    > * {
      margin: 0;
    }
  }

    .c-cart__title {
      @include h5;

      flex: 1 0 auto;
      margin-right: rem($spacing-small);
      font-family: var(--font-brand);
    }

    .c-cart__action {
      flex: 0 0 auto;
      font-weight: $font-bold;
    }

  .c-cart__body {}

    .c-cart__header {
      padding-bottom: rem($spacing-regular);
      border-bottom: rem(1px) solid var(--color-primary-400);

      > * {
        margin: 0;
      }

      > * + * {
        margin-top: rem($spacing-regular);
      }

      .c-cart__main + & {
        margin-top: rem($spacing-regular);
      }
    }

      .c-cart__count {
        font-weight: $font-bold;
      }

    .c-cart__main {}

      .c-cart__items {
        padding: 0;
        margin: 0;
        list-style: none;
      }

        .c-cart__item {
          padding: rem($spacing-regular) 0;
          border-bottom: rem(1px) solid var(--color-primary-400);

          &:last-child {
            border-bottom: 0;
          }
        }

    .c-cart__footer {
      border-top: rem(1px) solid var(--color-primary-400);
    }

  .c-cart__foot {
    font-size: rem($font-size-regular);
    border-top: rem(1px) solid var(--color-primary-400);
  }

    .c-cart__line {
      display: flex;
      padding: rem($spacing-regular) 0;

      > * {
        margin: 0;
      }

      .c-cart__foot & {
        align-items: center;
      }
    }

      .c-cart__label {
        flex: 1 1 auto;
        margin-right: rem($spacing-small);

        .c-cart__foot & {
          font-weight: $font-bold;
        }
      }

      .c-cart__price {
        flex: 0 0 auto;
        font-size: rem($font-size-regular);
        font-weight: $font-bold;

        .c-cart__foot & {
          @include h4;

          font-family: var(--font-brand);
        }
      }

      .c-cart__button {}

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@include mq($until: small) {
  .c-cart__button {
    width: 100%;
  }
}

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@media print {
  .c-cart {
    break-inside: avoid;
  }
}

/**
 * Animations
 **********************************************************************************/

 @keyframes ripple-effect {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0);
  }

  50% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.5);
  }
}
