/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Logo
 * Type: Component
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-logo {
  display: inline-block;
  font-weight: $font-regular;

  > * { margin: 0; }
}

  .c-logo__title {
    display: block;
    font-family: var(--font-brand);
    font-weight: $font-bold;
    line-height: 1.2;
  }

  .c-logo__subtitle {
    display: block;
    font-size: em(8px, $font-size-regular);
  }

/*******************************************************************************
 * Interactive
*******************************************************************************/

a.c-logo {
  text-decoration: none;

  .c-logo__title {
    text-decoration: underline;
    text-decoration-color: transparent;
    text-decoration-thickness: em(1px, $font-size-regular);

    @include transition(text-decoration);
  }

  &:hover {
    text-decoration: none;

    .c-logo__title {
      text-decoration-color: currentcolor;
    }

    .c-logo__subtitle {
      text-decoration: none;
    }
  }
}

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@media print {
  .c-logo {
    break-inside: avoid;
  }
}
