/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Richtext
 * Type: Scope
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

$richtext-font-size: $font-size-regular;
$richtext-line-height: $global-line-height;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.s-richtext {
  --vertical-space: 1.5em;

  // Global spacings

  > * {
    margin-top: 0;
    margin-bottom: 0;

    + * {
      margin-top: var(--vertical-space);
    }
  }

  // Headings

  h1,
  .u-h1,
  h2,
  .u-h2,
  h3,
  .u-h3,
  h4,
  .u-h4,
  h5,
  .u-h5,
  h6,
  .u-h6 {
    font-family: var(--font-brand);
    font-weight: $font-bold;
  }

  h1 + h2,
  h2 + h3,
  h3 + h4,
  h4 + h5,
  h5 + h6 {
    margin-top: 0.25em;
  }

  // Inlines

  strong,
  b {
    font-weight: $font-bold;
  }

  small {
    font-size: 0.875em;
  }

  // Links

  a {
    font-weight: $font-bold;
    text-decoration: underline;
    color: inherit;
    transition: color $animations-transition-duration;

    &:hover {
      text-decoration: none;
    }
  }

  // Lists

  ul,
  ol {
    padding-left: 2em;

    > * {
      margin-top: 0;

      + * {
        margin-top: $richtext-line-height * 0.5em;
      }
    }

    ul,
    ol {
      margin-top: $richtext-line-height * 0.5em;
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal-leading-zero;
  }

    li::marker {
      font-weight: $font-black;
    }

  // Blockquote

  blockquote {
    margin-right: 0;
    margin-left: 0;
    font-family: var(--font-brand);
    font-size: em($font-size-medium, $richtext-font-size);
    font-weight: $font-bold;

    > * {
      margin-top: 0;
      margin-bottom: 0;

      + * {
        margin-top: $richtext-line-height * 1em;
      }
    }
  }

  hr {
    border-style: solid;
  }
}

.s-richtext--color {
  color: var(--color-black);

  h1,
  h2 {
    color: var(--color-foreground);
  }

  h3 {
    color: var(--color-secondary-800);
  }

  li::marker {
    color: var(--color-primary-500);
  }

  hr {
    color: var(--color-primary-300);
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .s-richtext {
    a[href]::after {
      content: " [" attr(href) "]";
      font-style: italic;
      opacity: 0.5;
    }

    abbr[title]::after {
      content: " [" attr(title) "]";
      font-style: italic;
      opacity: 0.5;
    }
  }
}
