/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Banner
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:map";

/*******************************************************************************
 * Variables
*******************************************************************************/

$banner-font-size: $font-size-regular;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-banner {
  padding-top: rem($spacing-large);
  padding-bottom: rem($spacing-large);
  font-size: rem($banner-font-size);
}

  .c-banner__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: rem($spacing-regular);
  }

    .c-banner__main {
      flex-basis: 0;
      flex-grow: 999;
      min-width: 60%;

      > * {
        margin-top: 0;
        margin-bottom: 0;
      }

      > * + * {
        margin-top: rem($spacing-small);
      }
    }

      .c-banner__breadcrumb {}

      .c-banner__content {
        > * {
          margin-top: 0;
          margin-bottom: 0;
        }

        > * + * {
          margin-top: rem($spacing-small);
        }
      }

        .c-banner__suptitle {
          font-size: rem($font-size-small);
          font-weight: $font-bold;
          text-transform: uppercase;
          color: var(--color-secondary-700);
        }

        .c-banner__title {
          @include h-m;

          font-family: var(--font-brand);
          font-weight: $font-bold;
        }

        .c-banner__richtext {
          max-width: rem(map.get($wrappers-sizes, "small"));
        }

    .c-banner__aside {
      flex-basis: rem(400px);
      flex-grow: 1;
    }

/*******************************************************************************
 * Variants
*******************************************************************************/

.c-banner--wrap {
  .c-banner__title {
    max-width: rem(map.get($wrappers-sizes, "small"));
  }
}

.c-banner--big {
  .c-banner__title {
    @include h-l;
  }
}

.c-banner--center {
  .c-banner__content {
    max-width: rem(map.get($wrappers-sizes, "small"));
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }

    .c-banner__title {
      @include h1;
    }
}

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@media print {
  .c-banner {
    break-inside: avoid;
  }
}
