/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Testimony
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$testimony-font-size: $font-size-regular;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-testimony {
  font-size: rem($testimony-font-size);
  text-align: center;
}

  .c-testimony__blockquote {
    margin: 0;

    > * {
      margin-top: 0;
      margin-bottom: 0;
    }

    > * + * {
      margin-top: rem($spacing-large);
    }
  }

    .c-testimony__verbatim {
      @include h4;

      padding: rem($spacing-large);
      font-family: var(--font-brand);
      font-weight: $font-bold;
      border-top: rem(1px) solid;
      border-bottom: rem(1px) solid;
    }

    .c-testimony__footer {
      > * {
        margin-top: 0;
        margin-bottom: 0;
      }

      > * + * {
        margin-top: rem($spacing-tiny);
      }
    }

      .c-testimony__title {
        @include h5;

        font-family: var(--font-brand);
        font-weight: $font-bold;
      }

      .c-testimony__subtitle {}

/*******************************************************************************
 * Variants
*******************************************************************************/

.c-testimony--small {
  .c-testimony__verbatim {
    @include h5;
  }
}

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@media print {
  .c-testimony {
    break-inside: avoid;
  }
}
