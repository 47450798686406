/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Gift Card
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:math";

/*******************************************************************************
 * Variables
*******************************************************************************/

$gift-card-font-size-min: 10px;
$gift-card-font-size-max: 18px;
$gift-card-radius: 7px;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-gift-card {
  --foreground: var(--color-primary-800);
  --background: var(--color-secondary-100);
  --background-dark: var(--color-secondary-200);
  --ratio: #{ math.div(400, 264) };
  --shadow: rgba(0, 0, 0, 0.1);

  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: em(15px 20px, $gift-card-font-size-min);
  font-size: rem($gift-card-font-size-min);
  @include fluid-size($gift-card-font-size-min, $gift-card-font-size-max, font-size, 320px, 560px);
  text-align: left;
  color: var(--foreground);
  background-color: var(--background);
  border-radius: em($gift-card-radius, $gift-card-font-size-min);
  aspect-ratio: 312 / 204;
  @include shadow(0, rem(2px), rem(8px));
}

  .c-gift-card__decorations {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;
  }

    .c-gift-card__leaves {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 76%;
      height: auto;
      color: var(--background-dark);
      opacity: 0.3;
    }

    .c-gift-card__bird {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 65%;
      height: auto;
    }

  .c-gift-card__head,
  .c-gift-card__foot {
    flex: 0 0 auto;
  }

  .c-gift-card__head {
    > * {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

    .c-gift-card__option {
      position: absolute;
      top: em(15px, $gift-card-font-size-min);
      left: em(20px, $gift-card-font-size-min);
      width: auto;
      transform: translate(-100%, 15%);
    }

    .c-gift-card__label {
      @include h5;

      font-family: var(--font-brand);
      font-weight: $font-bold;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }

    .c-gift-card__title {
      font-family: var(--font-brand);
      font-size: 2.5em;
      font-weight: $font-bold;
    }

    .c-gift-card__subtitle {}

  .c-gift-card__body {
    position: relative;
    flex: 1 0 auto;
    font-size: 1rem;
  }

  .c-gift-card__foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: rem($spacing-small);
    font-size: 1rem;
    color: var(--color-primary-600);
  }

    .c-gift-card__field {
      position: relative;
      z-index: 1;
    }

    .c-gift-card__price {
      @include h6;
    }

    .c-gift-card__icon {
      @include fluid-size(32px, 42px);

      align-self: flex-end;
      margin-left: rem($spacing-regular);
      color: var(--color-primary-400);
    }

/*******************************************************************************
 * Modifiers
*******************************************************************************/

.c-gift-card--choice {
  --background: var(--color-primary-50);
  --background-dark: var(--color-primary-100);

  padding-left: em(26px, $gift-card-font-size-min);

  @include transition(background-color box-shadow);

  &.is-checked {
    --background: var(--color-secondary-100);
    --background-dark: var(--color-secondary-200);

    @include shadow(0, rem(10px), rem(20px));
  }

  .c-gift-card__leaves {
    @include transition(color);
  }
}

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@media print {
  .c-gift-card {
    break-inside: avoid;
    border: rem(1px) solid color("black");
  }

    .c-gift-card__leaves {
      opacity: 0.1;
    }
}
