/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Badge
 * Type: Component
**/

@use "sass:math";

/**
 * Variables
*******************************************************************************/

$badge-font-size: $font-size-small;
$badge-border-width: $global-border-width;

/*******************************************************************************
* Default styles
*******************************************************************************/

.c-badge {
  --foreground: var(--color-black);
  --background: transparent;
  --border: var(--color-primary-300);

  @include appearance;
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: em(1px 4px, $badge-font-size);
  font-family: var(--font-brand);
  font-size: rem($badge-font-size);
  font-weight: $font-regular;
  line-height: math.div(22px, $badge-font-size);
  text-align: center;
  text-decoration: none;
  color: var(--foreground);
  background: none var(--background);
  border: rem($badge-border-width) solid var(--border);
  border-radius: 0;
}

  .c-badge__label {
    position: relative;
    display: inline-block;
    flex: 1 1 auto;
    margin: em(2px, $badge-font-size);
  }

/*******************************************************************************
 * States
*******************************************************************************/

// Interactive

a.c-badge,
button.c-badge {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

/*******************************************************************************
 * Variant
*******************************************************************************/

.c-badge--primary {
  --background: var(--color-warning-300);
  --border: transparent;

  font-weight: $font-bold;
}

.c-badge--neutral {
  --foreground: inherit;
  --border: var(--color-neutral-500);

  font-weight: $font-bold;
}

.c-badge--promo {
  --foreground: var(--color-error-700);
  --background: var(--color-error-100);
  --border: transparent;

  font-weight: $font-bold;
}

.c-badge--big {
  font-size: rem(18px);
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-badge {
    break-inside: avoid;

    * {
      color: inherit !important;
    }
  }

  .c-badge--primary {
    color: color("white") !important;
    background-color: color("black") !important;
  }
}
