/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Fonts
 * Type: Generic
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

@include
  font-face(
    "Rufina",
    "rufina-regular",
    $font-regular,
    normal,
  );

@include
  font-face(
    "Rufina",
    "rufina-bold",
    $font-bold,
    normal,
  );
