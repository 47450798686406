/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Showcase
 * Type: Component
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-showcase {
  --gap: #{ rem(24px) };
  --min: #{ rem(280px) };

  display: grid;
  grid-gap: var(--gap);
  grid-template-areas:
    "heading"
    "introduction"
    "items";
}

  .c-showcase__heading {
    grid-area: heading;
    padding: rem($spacing-small) 0;

    > * {
      margin-top: 0;
      margin-bottom: 0;
    }

    > * + * {
      @include fluid-size($spacing-small, $spacing-regular, margin-top);
    }
  }

    .c-showcase__title {
      @include h-xl;

      font-family: var(--font-brand);
    }

    .c-showcase__subtitle {
      @include fluid-size(18px, 24px);
      font-weight: $font-bold;
      color: var(--color-secondary-700);
    }

  .c-showcase__introduction {
    grid-area: introduction;
  }

  .c-showcase__items {
    display: grid;
    grid-gap: var(--gap);
    grid-area: items;

    @supports (width: min(var(--min), 100%)) {
      grid-template-columns: repeat(auto-fit, minmax(min(var(--min), 100%), 1fr));
    }
  }

    .c-showcase__item {}

      .c-showcase__richtext {
        padding-bottom: rem($spacing-regular);
        border-bottom: rem(1px) solid currentcolor;
      }

      .c-showcase__card {}

/*******************************************************************************
 * Media Queries
*******************************************************************************/
@include mq($from: large) {
  .c-showcase {
    grid-template-columns: 1fr 2fr;
    grid-template-areas:
      "heading heading"
      "introduction items";
  }

    .c-showcase__heading {
      position: relative;
      padding-top: rem($spacing-regular);
      padding-right: 38%;
      padding-bottom: rem($spacing-regular);
      margin-bottom: rem($spacing-regular);

      &::before,
      &::after {
        content: "";
        position: absolute;
        font-size: rem(100px);
        transform: translate(-50%, -50%);
      }

      &::before {
        top: 33%;
        right: 16%;
        width: 1em;
        height: 1em;
        background-color: var(--color-secondary-500);
        border-bottom-left-radius: 100%;
      }

      &::after {
        right: 4%;
        bottom: 8%;
        width: 1em;
        height: 1em;
        background-color: var(--color-warning-500);
        border-radius: 50%;
      }
    }

    .c-showcase__richtext {
      margin-right: 25%;
    }
}
