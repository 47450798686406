/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Offer
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$offer-font-size: $font-size-regular;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-offer {
  --foreground: var(--color-neutral-800);
  --background: var(--color-secondary-100);

  position: relative;
  font-size: rem($offer-font-size);

  > * {
    margin: 0;
  }

  > * + * {
    margin-top: rem(20px);
  }
}

  .c-offer__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    min-height: rem(327px);
    @include fluid-size(24px, 32px, padding);
    color: var(--foreground);
    background-color: var(--background);

    @supports (aspect-ratio: 1 / 1) {
      min-height: 0;
      aspect-ratio: 1 / 1;
    }

    > * {
      margin: 0;
    }

    > * + * {
      margin-top: rem(15px);
    }
  }

    .c-offer__pastil {
      --foreground: var(--color-primary-500);
      --border: var(--color-secondary-800);

      font-size: rem(64px);
    }

    .c-offer__title {
      @include h3;

      font-family: var(--font-brand);
      font-weight: $font-bold;
    }

      .c-offer__mainlink {
        text-decoration: none;
      }

    .c-offer__subtitle {}

  .c-offer__foot {
    > * {
      margin: 0;
    }

    > * + * {
      margin-top: rem(12px);
    }
  }

    .c-offer__button {
      width: 100%;
    }

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@media print {
  .c-offer {
    break-inside: avoid;
  }

    .c-offer__body {
      border: rem(1px) solid color("black");
    }
}
