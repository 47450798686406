/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Details
 * Type: Details
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$details-font-size: $font-size-small;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-details {
  font-size: rem($details-font-size);
  color: var(--color-foreground);
  background-color: var(--color-background);
  border-radius: rem(10px);
}

  .c-details__summary {
    display: flex;
    align-items: center;
    padding: rem($spacing-regular);
    font-size: rem($font-size-regular);
    text-decoration: none;

    &::-webkit-details-marker,
    &::after {
      display: none;
    }

    // Firefox
    &::marker {
      font-size: 0;
    }

    &:hover {
      .c-details__marker {
        transform: rotate(0.5turn);
      }
    }

    ~ * {
      display: none;
      animation: details-open $animations-transition-duration $animations-timing-function;
    }
  }

    .c-details__label {
      flex: 1 1 auto;
    }

    .c-details__marker {
      position: relative;
      display: inline-block;
      flex: 0 0 auto;
      width: em(15px, $details-font-size);
      height: em(15px, $details-font-size);
      margin-left: rem($spacing-regular);
      transform: rotate(0turn);
      @include transition(transform);

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        display: inline-block;
        width: em(15px, $details-font-size);
        height: em(2px, $details-font-size);
        background-color: var(--color-secondary-700);
        border-radius: em(2px, $details-font-size);
        transform: translate(-50%, -50%) rotate(0turn);
        @include transition(transform);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(0.25turn);
      }
    }

  .c-details__content {
    padding: rem($spacing-regular);
    padding-top: 0;
  }

/*******************************************************************************
 * States
*******************************************************************************/

.c-details[open] {
  .c-details__summary ~ * {
    display: block;
  }

    .c-details__marker {
      &::before {
        transform: translate(-50%, -50%) rotate(0.5turn);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(1turn);
      }
    }
}

/*******************************************************************************
 * Animations
*******************************************************************************/

@keyframes details-open {
  from {
    opacity: 0;
    transform: translate3d(0, #{ rem(-1 * $spacing-regular) }, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-details {
    break-inside: avoid;
    border: rem(1px) solid color("black") !important;
  }

    .c-details__marker {
      &::before,
      &::after {
        background-color: color("black") !important;
      }
    }
}
