/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Pricing card
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$pricing-card-font-size: $font-size-regular;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-pricing-card {
  position: relative;
  width: 100%;
  padding: rem(15px);
  font-size: rem($pricing-card-font-size);
  color: color("foreground");
  background-color: color("white");
  border: rem(1px) solid color("primary-100");
  border-radius: em(10px, $pricing-card-font-size);
}

  .c-pricing-card__head,
  .c-pricing-card__body {
    padding: rem(15px);
  }

  .c-pricing-card__head {
    text-align: center;

    > * {
      margin-top: 0;
      margin-bottom: 0;

      + * {
        margin-top: rem($spacing-regular);
      }
    }
  }

    .c-pricing-card__heading {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > * {
        margin-top: 0;
        margin-bottom: 0;

        + * {
          margin-top: rem($spacing-small);
        }
      }

      @include mq($from: large) {
        min-height: em(170px, $pricing-card-font-size);
      }
    }

      .c-pricing-card__title,
      .c-pricing-card__subtitle {
        font-family: var(--font-brand);
        font-weight: $font-bold;
      }

      .c-pricing-card__title {
        @include h2;

        line-height: 1.2;
      }

      .c-pricing-card__subtitle {
        @include h5;
      }

    .c-pricing-card__prices {
      color: color("primary-600");
    }

      .c-pricing-card__price {
        position: relative;
        display: block;

        & + & {
          padding-top: rem(17px);
          margin-top: rem(16px);
          font-size: em($font-size-small, $pricing-card-font-size);

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            display: block;
            width: rem(16px);
            height: rem(1px);
            margin-left: rem(-8px);
            background-color: color("primary-300");
          }
        }
      }

    .c-pricing-card__description {
      margin-top: rem(5px);
      color: var(--color-black);
    }

    .c-pricing-card__entities {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

      .c-pricing-card__entity {
        display: inline-flex;
        align-items: center;
        padding: rem(12px);
      }

        .c-pricing-card__entity-checkbox {
          margin-right: rem(8px);
          font-size: rem(18px);
          color: var(--color-primary);
        }

        .c-pricing-card__entity-name {}

    .c-pricing-card__image {
      max-width: 100%;
      height: auto;
      max-height: 11.25rem;
      padding: 1em;
      border: 0.0625rem solid #ddd;
      border-radius: 1em;
    }

    .c-pricing-card__actions {
      > * {
        margin-top: 0;
        margin-bottom: 0;

        + * {
          margin-top: rem($spacing-small);
        }
      }
    }

      .c-pricing-card__button {}

  .c-pricing-card__body {}

    .c-pricing-card__toggler {
      display: flex;
      margin-right: auto;
      margin-left: auto;

      .no-js &,
      .c-pricing-card.is-mounted &:not([aria-expanded]) {
        display: none;
      }

      &[aria-expanded="true"] {
        display: none;
      }

      @include mq($from: large) {
        display: none;
      }
    }

    .c-pricing-card__checklist {
      color: color("black");

      &[aria-hidden] {
        animation: pricing-card-details-show $animations-transition-duration $animations-timing-function;
        @include transition(opacity transform);
      }

      &.is-hiding {
        opacity: 0;
        transform: translate3d(0, #{ rem(-1 * $spacing-regular) }, 0);
      }

      &[aria-hidden="false"] { display: block; }
      &[aria-hidden="true"] { display: none; }
    }

    .c-pricing-card__price-list {
      color: color("black");
    }

/*******************************************************************************
 * Modifiers
*******************************************************************************/

.c-pricing-card--small {

  .c-pricing-card__heading {
    min-height: 0;
  }

    .c-pricing-card__title {
      @include h3;
    }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-pricing-card {
    break-inside: avoid;
  }
}

/*******************************************************************************
 * Animations
*******************************************************************************/

@keyframes pricing-card-details-show {
  from {
    opacity: 0;
    transform: translate3d(0, #{ rem(-1 * $spacing-regular) }, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
