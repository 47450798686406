/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Cart Item
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$cart-item-font-size: $font-size-small;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-cart-item {
  display: flex;
  font-size: rem($cart-item-font-size);
}

.c-cart-item__head {
  flex: 0 0 auto;
  padding-right: rem($spacing-regular);
}

.c-cart-item__image {
}

.c-cart-item__body {
  display: grid;
  flex: 1 1 auto;
  align-self: center;
  gap: rem(4px);
  grid-template:
      "title"
      "subtitle"
      "badge"
      "quantity"
      "price"
      "action";

  > * {
    margin: 0;
  }
}

.c-cart-item__title {
  grid-area: title;
  font-size: 1em;
}

.c-cart-item__subtitle {
  grid-area: subtitle;
}

.c-cart-item__badge {
  grid-area: badge;
}

.c-cart-item__price {
  grid-area: price;
}

.c-cart-item__quantity {
  grid-area: quantity;
  width: fit-content;
}

.c-cart-item__action {
  grid-area: action;
  text-align: right;
}

.c-cart-item__asterisk {
  font-weight: bold;
  color: #9a0000;
}

.c-cart-item__link {
  --color: var(--color-neutral-600);
  --color-icon: inherit;
}

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@include mq($from: large) {
  .c-cart-item {
    font-size: rem($font-size-regular);
  }

  .c-cart-item__body {
    grid-template:
        "title quantity price"
        "subtitle quantity price"
        "badge quantity price"
        "action action action" / 1fr auto auto;
  }

  .c-cart-item__quantity {
    padding: 0 rem(8px);
  }

  .c-cart-item__price,
  .c-cart-item__action {
    text-align: right;
  }
}

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@media print {
  .c-cart-item {
    break-inside: avoid;
  }
}
